<template>
  <v-container>
    <v-sheet min-height=400 max-width=400 class="mx-auto">
      <v-container>
        <v-row>
          <v-col
            v-for="(family, name, index) in families"
            :key="index"
            cols=12
          >
            <Familia
              :name="name"
            >
            </Familia>
          </v-col>
          <v-col cols=12>
            <v-btn
              fab
              bottom
              :style="{left: '50%', transform:'translateX(-50%)'}"
              @click="showDialog = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <AddFamilyDialog :show="showDialog" @close="showDialog = false"></AddFamilyDialog>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<script>
import Familia from './Familia.vue';
import AddFamilyDialog from './AddFamilyDialog.vue';

export default {
  components: {
    Familia,
    AddFamilyDialog,
  },
  data: () => ({
    showDialog: false,
  }),
  computed: {
    families() {
      return this.$store.state.families;
    },
  },
};
</script>
