<template>
  <v-app>
   <v-app-bar
      app
      flat
    >
      <v-tabs
        v-model="tabs"
        centered
        class="ml-n9"
      >
        <v-tab>Turnos</v-tab>
        <v-tab>Familias</v-tab>
        <v-tab>Calendario</v-tab>
      </v-tabs>

      <v-tooltip bottom v-if="mode === 'development'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            bottom
            left
            absolute
            @click="resetStoreWithMock"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-database-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Reset with mock data!</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            bottom
            right
            absolute
            @click="dialog = true"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-delete-forever-outline</v-icon>
          </v-btn>
        </template>
        <span>¡BORRAR DATOS!</span>
      </v-tooltip>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
        <v-card-title class="headline">
          ¿Borrar datos?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="resetStoreWithInitial"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

    </v-app-bar>

    <v-main>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <Turnos/>
        </v-tab-item>
        <v-tab-item>
          <Familias/>
        </v-tab-item>
        <v-tab-item>
          <Calendario/>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </v-app>
</template>

<script>
import Calendario from './components/Calendario.vue';
import Turnos from './components/Turnos.vue';
import Familias from './components/Familias.vue';

export default {
  name: 'App',

  components: {
    Calendario,
    Turnos,
    Familias,
  },

  data: () => ({
    mode: process.env.NODE_ENV,
    tabs: null,
    dialog: false,
  }),
  methods: {
    resetStoreWithInitial() {
      this.dialog = false;
      this.$store.commit('reset_with_initial_state');
    },
    resetStoreWithMock() {
      this.$store.commit('reset_with_mock_state');
    },
  },
};
</script>
