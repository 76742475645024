<template>
  <v-dialog
  :value="show"
  @click:outside="close"
  max-width="400px"
  >
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="name"
          label="Nombre"
          prepend-icon="mdi-account-group"
          required
        >
        </v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          color="primary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          :disabled="!name"
          text
          color="primary"
          @click="add_family"
        >
          Añadir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    name: undefined,
  }),
  computed: {
  },
  methods: {
    add_family() {
      this.$store.commit('add_family', this.name);
      this.name = undefined;
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
