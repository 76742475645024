import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
// for mock data
import Helpers from './helpers';

Vue.use(Vuex);

const getEmptyState = () => ({
  calender_start: Helpers.date2string(new Date(), -15),
  calender_end: Helpers.date2string(new Date(), 15),
  slots: [],
  families: {},
  calenders: {},
  selected_calender_name: '',
});

const getEmptyFamily = () => ({
  slots: [],
  favorites: [],
  three: false,
});

const getInitialState = () => ({
  calender_start: Helpers.date2string(new Date(), -15),
  calender_end: Helpers.date2string(new Date(), 15),
  slots: [],
  families: {
    Alberta: getEmptyFamily(),
    Ali: getEmptyFamily(),
    Conchita: getEmptyFamily(),
    Eli: getEmptyFamily(),
    Inés: getEmptyFamily(),
    Iria: getEmptyFamily(),
    Jose: getEmptyFamily(),
    Marina: getEmptyFamily(),
    Marta: getEmptyFamily(),
    Miriam: getEmptyFamily(),
    Pat: getEmptyFamily(),
    Roberta: getEmptyFamily(),
    Tati: getEmptyFamily(),
    Yolanda: getEmptyFamily(),
  },
  calenders: {},
  selected_calender_name: '',
});

function getBaseState() {
  if (window.localStorage.length === 0) {
    return getInitialState();
  }
  return getEmptyState();
}

const getMockState = () => {
  const slots = Helpers.mockSlots();
  const families = Helpers.mockFamilies(slots.length);

  return {
    calender_start: Helpers.date2string(new Date(), -15),
    calender_end: Helpers.date2string(new Date(), 15),
    slots,
    families,
    calenders: Helpers.mockCalenders(slots, families),
    selected_calender_name: 'opción 1',
  };
};

export default new Vuex.Store({
  state: getBaseState(),
  mutations: {
    reset_with_initial_state(state) {
      Object.assign(state, getInitialState());
    },
    reset_with_mock_state(state) {
      Object.assign(state, getMockState());
    },
    calender_start(state, startString) {
      state.calender_start = startString;
    },
    calender_end(state, endString) {
      state.calender_end = endString;
    },
    add_slot(state, slot) {
      state.slots.push(slot);
    },
    remove_slot(state, index) {
      state.slots.splice(index, 1);
    },
    add_family(state, name) {
      const emptyFamily = {
        slots: [],
        favorites: [],
        three: false,
      };
      Vue.set(state.families, name, emptyFamily);
    },
    remove_family(state, name) {
      Vue.delete(state.families, name);
    },
    update_family_slots(state, payload) {
      state.families[payload.name].slots = payload.slots;
    },
    update_family_favorites(state, payload) {
      state.families[payload.name].favorites = payload.favorites;
    },
    update_family_three(state, payload) {
      state.families[payload.name].three = payload.three;
    },
    set_calenders(state, calenders) {
      state.calenders = calenders;
    },
    add_calender(state, payload) {
      Vue.set(state.calenders, payload.name, payload.calender);
    },
    set_selected_calender_name(state, selectedCalenderName) {
      state.selected_calender_name = selectedCalenderName;
    },
  },
  plugins: [new VuexPersistence().plugin],
});
