<template>
  <v-row>
    <v-col>
      <v-toolbar
        flat
        height=100
      >
        <v-row align="center">
          <v-col cols=6 align="right">
            <v-btn
              outlined
              right
              :loading="loading"
              :disables="loading"
              class="mr-4"
              color="primary"
              @click="requestCalenders"
            >
              abracadabra ...
            </v-btn>
          </v-col>
          <v-col cols=6 align="left">
            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  :disabled="calender_names.length < 1"
                  v-bind="attrs"
                  v-on="on"
                >
                <!-- color="grey darken-2" -->
                  <span>{{ selected_calender_name }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(option, index) in calender_names"
                  :key="index"
                  @click="selected_calender_name = option"
                >
                  <v-list-item-title>{{ option }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <v-select
              full-width
              :disabled="calender_names.length < 1"
              v-model="selected_calender_name"
              :items="calender_names"
              label="opción ..."
            ></v-select> -->
          </v-col>
        </v-row>
      </v-toolbar>
      <v-sheet height="1000">
        <v-calendar
          type="custom-weekly"
          :start="calender_start"
          :end="calender_end"
          :weekdays="weekdays"
          :events="events"
          :event-color="getEventColor"
        >
        </v-calendar>
      </v-sheet>
      <v-snackbar v-model="noSolutionSnackbar" top>
        {{ noSolutionText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="noSolutionSnackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="errorSnackbar" top>
        {{ errorText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="errorSnackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
const axios = require('axios');

export default {
  data: () => ({
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    loading: false,
    noSolutionSnackbar: false,
    noSolutionText: 'No hay solución ...',
    errorSnackbar: false,
    errorText: 'error ...',
  }),
  computed: {
    calender_start() {
      return this.$store.state.calender_start;
    },
    calender_end() {
      return this.$store.state.calender_end;
    },
    slots() {
      return this.$store.state.slots;
    },
    families() {
      return this.$store.state.families;
    },
    calenders: {
      get() {
        return this.$store.state.calenders;
      },
      set(value) {
        this.$store.commit('set_calenders', value);
      },
    },
    selected_calender_name: {
      get() {
        return this.$store.state.selected_calender_name;
      },
      set(value) {
        this.$store.commit('set_selected_calender_name', value);
      },
    },
    calender_names() {
      return Object.keys(this.calenders);
    },
    events() {
      return this.calenders[this.selected_calender_name];
    },
  },
  methods: {
    getEventColor(event) {
      return event.color;
    },
    requestCalenders() {
      this.loading = true;
      const body = { families: this.families, nr_of_slots: this.slots.length };

      axios.post('http://localhost:8000/calenders/', body)
      // axios.post('http://patopi.ddns.net:7007/calenders', body)
        .then((response) => { this.addCalenders(response); })
        .catch((error) => { this.requestError(error); });
    },
    requestError(error) {
      setTimeout(() => this.stopLoading(), 400);
      this.errorSnackbar = true;
      console.log(error);
    },
    addCalenders(response) {
      setTimeout(() => this.stopLoading(), 400);
      console.log(response);

      this.selected_calender_name = '';
      this.calenders = {};

      if (response.data.length === 0) {
        this.noSolutionSnackbar = true;
      } else {
        for (let i = 0; i < response.data.length; i += 1) {
          const name = `opción ${i + 1}`;
          this.$store.commit('add_calender', { name, calender: this.computeEvents(response.data[i]) });
          // this.calenders[name] = this.computeEvents(response.data[i]);
        }
        // eslint-disable-next-line prefer-destructuring
        this.selected_calender_name = this.calender_names[0];
      }
    },
    computeEvents(calender) {
      const events = [];
      for (let i = 0; i < Object.keys(calender).length; i += 1) {
        const names = calender[`slot${i}`];
        for (let j = 0; j < names.length; j += 1) {
          const event = {
            name: names[j],
            start: new Date(this.slots[i].start),
            end: new Date(this.slots[i].end),
            color: 'green',
          };
          events.push(event);
        }
      }

      return events;
    },
    stopLoading() {
      this.loading = false;
    },
  },
};
</script>
