<template>
  <v-card>
    <v-card-title class="justify-center">Calendario</v-card-title>
    <v-container>
      <v-row>
        <v-col cols=6>
          <v-menu
            ref="startMenu"
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="calender_start"
                label="Empieza"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="calender_start"
              no-title
              scrollable
              @input="startMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols=6>
          <v-menu
            ref="endMenu"
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="calender_end"
                label="Acaba"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                max-width="100"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="calender_end"
              no-title
              scrollable
              @input="endMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    startMenu: false,
    endMenu: false,
  }),
  computed: {
    calender_start: {
      get() {
        return this.$store.state.calender_start;
      },
      set(value) {
        this.$store.commit('calender_start', value);
      },
    },
    calender_end: {
      get() {
        return this.$store.state.calender_end;
      },
      set(value) {
        this.$store.commit('calender_end', value);
      },
    },
  },
};
</script>
