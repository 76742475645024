<template>
  <v-container>
    <v-sheet min-height="400" max-width=400 class="mx-auto">
      <v-container>
        <v-row>
          <v-col cols=12>
            <CalenderStartEnd/>
          </v-col>
          <v-col cols=12>
            <v-card>
              <v-card-title class="justify-center">Turnos</v-card-title>
              <v-list>
                <v-list-item
                  v-for="(slot, index) in slots"
                  :key="index"
                >
                  <v-list-item-avatar>
                    <v-icon
                    >
                      mdi-numeric-{{ index + 1 }}-box-multiple-outline
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="`${slot.title.split('-')[0]}`">
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="`${slot.title.split('-')[1]}`">
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      icon
                      @click="remove_slot(index)">
                      <v-icon
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    fab
                    :style="{left: '50%', transform:'translateX(-50%)'}"
                    @click="showDialog = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-list-item>
              </v-list>
              <AddSlotDialog :show="showDialog" @close="showDialog = false"></AddSlotDialog>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<script>
import CalenderStartEnd from './CalenderStartEnd.vue';
import AddSlotDialog from './AddSlotDialog.vue';

export default {
  components: {
    CalenderStartEnd,
    AddSlotDialog,
  },

  data: () => ({
    showDialog: false,
  }),
  computed: {
    slots() {
      return this.$store.state.slots;
    },
    calender_start() {
      return this.$store.state.calender_start;
    },
    calender_end() {
      return this.$store.state.calender_end;
    },
  },
  methods: {
    remove_slot(index) {
      this.$store.commit('remove_slot', index);
    },
  },
};
</script>
