<template>
  <v-card>
    <v-card-title>
      <v-icon left>mdi-account-group</v-icon>{{ name }}
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="remove_family"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-title>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">
            <v-row no-gutters>
              <v-col cols="4"> Trío? </v-col>
              <v-col cols="8" class="text--secondary">
                <v-fade-transition leave-absolute>
                  <span v-if="open" key="0"> ? </span>
                  <span v-else key="1">
                    {{ three ? "Sí" : "No" }}
                  </span>
                </v-fade-transition>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense no-gutters>
            <v-col cols="12">
              <v-checkbox
                v-model="three"
                label="Acepto los términos y condiciones de ser tres ..."
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">
            <v-row no-gutters>
              <v-col cols="4"> Turnos </v-col>
              <v-col cols="8" class="text--secondary">
                <v-fade-transition leave-absolute>
                  <span v-if="open" key="0"> Elige turnos ... </span>
                  <span v-else key="1">
                    {{ slots.map((x) => x+1) }}
                  </span>
                </v-fade-transition>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense no-gutters>
            <v-col cols="12">
              <v-checkbox
                v-model="selectAllSlots"
                label="Todos"
              >
              </v-checkbox>
            </v-col>
            <v-col v-for="(slot, index) in all_slots" :key="index" cols="12">
              <v-checkbox
                v-model="slots"
                :label="slot.title"
                :value="index"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">
            <v-row no-gutters>
              <v-col cols="4"> Familias </v-col>
              <v-col cols="8" class="text--secondary">
                <v-fade-transition leave-absolute>
                  <span v-if="open" key="0"> Elige familias ... </span>
                  <span v-else key="1">
                    {{ favorites }}
                  </span>
                </v-fade-transition>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense no-gutters>
            <v-col cols="12">
              <v-checkbox
                v-model="selectAllFavorites"
                label="Todas"
              >
              </v-checkbox>
            </v-col>
            <v-col
              v-for="(name, index) in all_favorites"
              :key="index"
              cols="12"
            >
              <v-checkbox
                v-model="favorites"
                :label="name"
                :value="name"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
const _ = require('lodash');

export default {
  props: {
    name: String,
  },
  computed: {
    all_slots() {
      return this.$store.state.slots;
    },
    all_favorites() {
      return Object.keys(this.$store.state.families)
        .filter((name) => name !== this.name);
    },
    three: {
      get() {
        return this.$store.state.families[this.name].three;
      },
      set(value) {
        this.$store.commit('update_family_three', { name: this.name, three: value });
      },
    },
    slots: {
      get() {
        return this.$store.state.families[this.name].slots;
      },
      set(value) {
        this.$store.commit('update_family_slots', { name: this.name, slots: value });
      },
    },
    favorites: {
      get() {
        return this.$store.state.families[this.name].favorites;
      },
      set(value) {
        this.$store.commit('update_family_favorites', { name: this.name, favorites: value });
      },
    },
    selectAllSlots: {
      get() {
        return _.isEqual(_.sortBy(this.slots), _.range(this.all_slots.length));
      },
      set(value) {
        if (value === true) {
          this.slots = _.range(this.all_slots.length);
        } else {
          this.slots = [];
        }
      },
    },
    selectAllFavorites: {
      get() {
        return _.isEqual(_.sortBy(this.favorites), _.sortBy(this.all_favorites));
      },
      set(value) {
        if (value === true) {
          this.$store.commit('update_family_favorites', { name: this.name, favorites: this.all_favorites });
        } else {
          this.$store.commit('update_family_favorites', { name: this.name, favorites: [] });
        }
      },
    },
  },
  methods: {
    remove_family() {
      this.$store.commit('remove_family', this.name);
    },
  },
};
</script>
