<template>
  <v-dialog
  :value="show"
  @click:outside="close"
  max-width="400px"
  >
    <v-card>
      <v-card-text>
        <v-menu
          ref="startMenu"
          v-model="startMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="slot_start"
              label="Slot start"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="slot_start"
            no-title
            scrollable
            :min="calender_start"
            :max="calender_end"
            @input="startMenu = false"
          >
          </v-date-picker>
        </v-menu>
        <v-menu
          ref="endMenu"
          v-model="endMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="slot_end"
              label="Slot end"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="slot_end"
            no-title
            scrollable
            :min="calender_start"
            :max="calender_end"
            @input="endMenu = false"
          >
          </v-date-picker>
        </v-menu>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          color="primary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          :disabled="!(slot_start && slot_end)"
          text
          color="primary"
          @click="add_slot(new Date(slot_start), new Date(slot_end))"
        >
          Añadir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Helper from '../store/helpers';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    slot_start: undefined,
    slot_end: undefined,
    startMenu: false,
    endMenu: false,
  }),
  computed: {
    calender_start() {
      return this.$store.state.calender_start;
    },
    calender_end() {
      return this.$store.state.calender_end;
    },
  },
  methods: {
    add_slot() {
      const slot = {
        start: this.slot_start,
        end: this.slot_end,
        title: Helper.buildSlotTitle(this.slot_start, this.slot_end),
      };
      this.$store.commit('add_slot', slot);
      this.slot_start = undefined;
      this.slot_end = undefined;
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
