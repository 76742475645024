const _ = require('lodash');

export default {

  date2string(date, daysToAdd = null) {
    let mDate = date;
    if (daysToAdd != null) {
      mDate = this.addDays(mDate, daysToAdd);
    }
    const day = mDate.getDate();
    const month = mDate.getMonth() + 1;
    const year = mDate.getFullYear();
    return `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  },

  addDays(date, days) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  },

  buildSlotTitle(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const options = {
      day: 'numeric', weekday: 'short', month: 'long',
    };
    return `${startDate.toLocaleString('es-es', options)} - ${endDate.toLocaleString('es-es', options)}`;
  },

  mockSlots() {
    let start = this.addDays(new Date(), -10);
    let end = this.addDays(start, 2);
    const slot1 = {
      start: this.date2string(start),
      end: this.date2string(end),
      title: this.buildSlotTitle(this.date2string(start), this.date2string(end)),
    };
    start = this.addDays(end, 0);
    end = this.addDays(start, 2);
    const slot2 = {
      start: this.date2string(start),
      end: this.date2string(end),
      title: this.buildSlotTitle(this.date2string(start), this.date2string(end)),
    };
    start = this.addDays(end, 5);
    end = this.addDays(start, 2);
    const slot3 = {
      start: this.date2string(start),
      end: this.date2string(end),
      title: this.buildSlotTitle(this.date2string(start), this.date2string(end)),
    };
    return [slot1, slot2, slot3];
  },

  mockFamilies(nrOfSlots) {
    const families = {};
    const names = [
      'Marina', 'Marta', 'Eli', 'Conchita', 'Myriam', 'Abel',
      // 'Inés', 'Roberta', 'Alicia', 'Oliva', 'Pat', 'Alberta', 'Taty',
    ];

    for (let i = 0; i < names.length; i += 1) {
      const name = names[i];

      let n = this.randInt(1, nrOfSlots);
      const slots = _.sampleSize(Array.from(Array(nrOfSlots).keys()), n);

      const otherNames = names.filter((x) => x !== name);
      n = this.randInt(1, otherNames.length);
      const favoritesIndices = _.sampleSize(Array.from(Array(otherNames.length).keys()), n);
      const favorites = favoritesIndices.map((x) => otherNames[x]);

      const three = _.sampleSize([true, false], 1)[0];

      families[name] = {
        slots,
        favorites,
        three,
      };
    }

    return families;
  },

  mockCalenders(slots, families) {
    const calenders = {};

    let events = [];
    for (let i = 0; i < slots.length; i += 1) {
      for (let j = (i * 2); j < (i * 2) + 2; j += 1) {
        const event = {
          name: Object.keys(families)[j],
          start: slots[i].start,
          end: slots[i].end,
          color: 'green',
        };
        events.push(event);
      }
    }
    calenders['opción 1'] = events;

    events = [];
    for (let i = 0; i < slots.length - 1; i += 1) {
      for (let j = (i * 3); j < (i * 3) + 3; j += 1) {
        const event = {
          name: Object.keys(families)[j],
          start: slots[i].start,
          end: slots[i].end,
          color: 'green',
        };
        events.push(event);
      }
    }
    calenders['opción 2'] = events;

    return calenders;
  },

  randInt(low = 0, high) {
    return Math.floor(low + (Math.random() * (high - low)));
  },
};
